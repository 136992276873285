import { useToast, type ToastParams } from "@component-utils/toasts"

const _avv_toast = (params: ToastParams) => useToast(params)
const _avv_toast_later = (params: ToastParams) => useToast(params, { later: true })

window.avv_toast = _avv_toast
window.avv_toast_later = _avv_toast_later

declare global {
  interface Window {
    avv_toast: typeof _avv_toast
    avv_toast_later: typeof _avv_toast_later
  }

  /** @deprecated Please use useToast directly */ 
  const avv_toast: typeof _avv_toast
  /** @deprecated Please use useToast directly */ 
  const avv_toast_later: typeof _avv_toast_later
}

export default {}
